import { FC } from "react";
import { x } from "@xstyled/emotion";
import { SystemProps } from "@xstyled/system";

interface ProductGridProps extends SystemProps {}

const ProductGrid: FC<ProductGridProps> = ({ children, ...props }) => {
  return (
    <x.div
      display="grid"
      gap={{ _: "1rem", lg: "2rem" }}
      gridTemplateColumns={{ _: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
      {...props}
    >
      {children}
    </x.div>
  );
};

export default ProductGrid;
